<template>
  <v-col
    v-if="filtersActive"
    class="shrink px-0 mx-0"
  >
    <v-tooltip top>
      <template #activator="{ on }">
        <v-hover>
          <v-btn
            x-small
            elevation="1"
            class="mt-1 py-3"
            color="primary accent-1"
            :fab="$vuetify.breakpoint.mobile"
            @click="$emit('clear')"
            v-on="on"
          >
            <v-icon
              x-small
              class="ms-0"
            >
              fa-solid fa-filter-slash
            </v-icon>
            <span
              v-if="!$vuetify.breakpoint.mobile"
              class="ms-1 "
            >{{ $t('Clear') }}</span>
          </v-btn>
        </v-hover>
      </template>
      <span>{{ $t('Clear Filters') }}</span>
    </v-tooltip>
  </v-col>
</template>
<script>
  export default {
    props: {
      filters: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      filtersActive () {
        return Object.values(this.filters).filter(x => x.length).length > 0
      },
    },
  }
</script>
